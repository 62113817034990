// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeModal__content--oQTwZ{font-size:16px;text-align:center}.WelcomeModal__title--KcSom{font-size:24px;font-weight:700;margin:20px 0}.WelcomeModal__lead--xY6u9{font-size:16px;margin:0 0 15px}.WelcomeModal__lead--xY6u9,.WelcomeModal__text--pKceV{text-align:left}.WelcomeModal__text--pKceV{background-color:#f3f3f3;margin:0;padding:15px}.WelcomeModal__text--pKceV>ul{list-style:none;margin:0;padding:0}.WelcomeModal__text--pKceV>ul>li{margin-left:1em;text-indent:-1em}.WelcomeModal__link--tWjQz{text-decoration:underline}@media only screen and (max-width:639px){.WelcomeModal__icon--kRLXI{height:auto;width:70px}.WelcomeModal__title--KcSom{font-size:18px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/modals/WelcomeModal.vue"],"names":[],"mappings":"AAEA,8BACE,cAAA,CACA,iBAAA,CAUF,4BACE,cAAA,CACA,eAAA,CACA,aAAA,CAOF,2BACE,cAAA,CACA,eACA,CAGF,sDAHE,eAOA,CAJF,2BACE,wBAAA,CACA,QAAA,CACA,YACA,CAEA,8BACE,eAAA,CACA,QAAA,CACA,SAAA,CAEA,iCACE,eAAA,CACA,gBAAA,CAKN,2BACE,yBAAA,CAzCA,yCADF,2BAEI,WAAA,CACA,UAAA,CAIJ,4BAMI,cAAA,CAVA","sourcesContent":["@import '@/scss/base/variables';\n\n.content {\n  font-size: 16px;\n  text-align: center;\n}\n\n.icon {\n  @media only screen and (max-width: $breakpoint-s) {\n    height: auto;\n    width: 70px;\n  }\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin: 20px 0;\n\n  @media only screen and (max-width: $breakpoint-s) {\n    font-size: 18px;\n  }\n}\n\n.lead {\n  font-size: 16px;\n  margin: 0 0 15px;\n  text-align: left;\n}\n\n.text {\n  background-color: #f3f3f3;\n  margin: 0;\n  padding: 15px;\n  text-align: left;\n\n  > ul {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n\n    > li {\n      margin-left: 1em;\n      text-indent: -1em;\n    }\n  }\n}\n\n.link {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "WelcomeModal__content--oQTwZ",
	"title": "WelcomeModal__title--KcSom",
	"lead": "WelcomeModal__lead--xY6u9",
	"text": "WelcomeModal__text--pKceV",
	"link": "WelcomeModal__link--tWjQz",
	"icon": "WelcomeModal__icon--kRLXI"
};
module.exports = ___CSS_LOADER_EXPORT___;
